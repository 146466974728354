<template>
  <footer class="footer-holder">
    <div class="footer_bottom">
      <div class="footer_bottom_holder">
        <h6>
          &copy;{{ year }} A product of
          <img class="abl-logo" src="../assets/images/abella-logo.svg" alt="" />
        </h6>
        <div class="social-icon-div">
          <a href="http://www.facebook.com/myshelta" class="fa fa-facebook"></a>
          <a
            href="http://www.instagram.com/myshelta"
            class="fa fa-instagram"
          ></a>
          <a href="http://www.twitter.com/myshelta" class="fa fa-twitter"></a>
          <a
            href="http://www.linkedin.com/company/myshelta"
            class="fa fa-linkedin"
          ></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterMain2",

  data() {
    return {
      year: ""
    };
  },

  mounted() {
    this.year = new Date().getFullYear();
    // console.log('Year in full', this.year);
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$black: #000000;
// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.footer_holder {
  margin: 20px 30px 30px 30px;
}

.footer_bottom {
  background-color: $primary;
}
.footer_bottom_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: -0.05em;
    padding-top: 11px;
    color: $white;
  }
}
.abl-logo {
  margin-bottom: 4px;
}

.fa-facebook,
.fa-instagram,
.fa-twitter,
.fa-linkedin {
  padding: 15px;
  font-size: 30px;
  width: 13.55px !important;
  height: 13.55px !important;
  text-align: center;
  text-decoration: none;
  margin: 15px 2px 5px 2px;
  position: relative;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-facebook::before {
  position: absolute;
  left: 33%;
  top: 23%;
  font-size: 1.2rem;
}

.fa-twitter {
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-twitter::before {
  position: absolute;
  left: 26%;
  top: 24%;
  font-size: 1.2rem;
}

.fa-linkedin {
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-linkedin::before {
  position: absolute;
  left: 27%;
  top: 20%;
  font-size: 1.2rem;
}

.fa-instagram {
  border: 1px solid #fff;
  color: white;
  border-radius: 3px;
}
.fa-instagram::before {
  position: absolute;
  left: 27%;
  top: 20%;
  font-size: 1.2rem;
}

.fa-question:before {
  color: $primary;
}
.abella-logo-img {
  padding-bottom: 0.2rem;
}
@media only screen and (max-width: 300px) {
  .footer_bottom_holder {
    display: block;
  }
}

@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  // .fa-facebook,
  // .fa.instagram,
  // .fa-twitter,
  // .linkedin {
  //   margin-top: 1.5rem;
  // }
  .help-holder {
    left: 36%;
    top: -70%;
  }
  .clippath-footer {
    clip-path: ellipse(99% 145% at 50% 164%);
    height: 26rem;
  }
  .login-reg-holder > p {
    padding-top: 7.2rem;
  }
  .login-reg-holder {
    padding: 0px 4rem 0 4rem;
  }
  .fa-facebook,
  .fa-instagram,
  .fa-linkedin,
  .fa-twitter {
    padding: 12px;
  }
  .fa-linkedin::before,
  .fa-facebook::before,
  .fa-instagram::before,
  .fa-twitter::before {
    font-size: 0.9rem;
  }
  .footer_bottom_holder {
    padding: 0 5px;
  }
}

// @media only screen and (max-width: 768px) {
//   .help-holder {
//     left: 36%;
//     top: 35%;
//   }
// }
</style>
